@font-face {
  font-family: "Buffalo";
  src: url("./fonts/BuffaloScript-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Akrobat";
  src: url("./fonts/Akrobat-ExtraLight.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

a,
a:visited {
  color: #ffffff;
  text-decoration: none;
}

ul,
li,
p {
  margin: 0;
  padding: 0;
}

.Carelnk {
  font-size: 16px;
}
.Carelnk .Carelnk__text {
  margin: 100px auto 0px auto;
  width: calc(100% - 200px);
  max-width: 600px;
  color: "#fff";
  padding: 20px;
  background: #000;
}
.Carelnk .Carelnk__text p {
  margin-bottom: 25px;
}
.Carelnk .Carelnk__text ul {
  margin-top: 10px;
  margin-left: 25px;
}
.Carelnk .Carelnk__text em {
  color: rgba(255, 129, 156, 0.9);
}

#bg img {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  z-index: -1;
  opacity: 0.2;
}

.SecureSources {
  font-size: 16px;
}
.SecureSources .SecureSources__text {
  margin: 100px auto 0px auto;
  width: calc(100% - 200px);
  max-width: 600px;
  color: "#fff";
  background: #000;
  padding: 20px;
}
.SecureSources .SecureSources__text p {
  margin-bottom: 25px;
}
.SecureSources .SecureSources__text ul {
  margin-top: 10px;
  margin-left: 25px;
}
.SecureSources .SecureSources__text em {
  color: rgba(255, 129, 156, 0.9);
}

.Ubisoft {
  font-size: 16px;
  margin-top: 200px;
}
.Ubisoft .Ubisoft__text {
  margin: 100px auto 0px auto;
  width: calc(100% - 200px);
  max-width: 600px;
  color: "#fff";
  background: #000;
  padding: 20px;
}
.Ubisoft .Ubisoft__text p {
  margin-bottom: 25px;
}
.Ubisoft .Ubisoft__text ul {
  margin-top: 10px;
  margin-left: 25px;
}
.Ubisoft .Ubisoft__text em {
  color: rgba(255, 129, 156, 0.9);
}

.Groundhogg {
  font-size: 16px;
  margin-top: 200px;
}
.Groundhogg .Groundhogg__text {
  margin: 100px auto 0px auto;
  width: calc(100% - 200px);
  max-width: 600px;
  color: "#fff";
}
.Groundhogg .Groundhogg__text p {
  margin-bottom: 25px;
}
.Groundhogg .Groundhogg__text ul {
  margin-top: 10px;
  margin-left: 25px;
}
.Groundhogg .Groundhogg__text em {
  color: rgba(255, 129, 156, 0.9);
}

.Home .Home__viewToggleBtn {
  position: absolute;
  font-size: 60px;
  top: 10px;
  right: 25px;
}

.Home.Home__galleryView {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}
.Home.Home__galleryView .Gallery__img img,
.Home.Home__galleryView .Gallery__imgSelected img {
  width: 200px;
  height: auto;
  margin: 1px;
  transform: translateZ(0);
}
.Home.Home__galleryView .Gallery__img .Gallery__caption,
.Home.Home__galleryView .Gallery__imgSelected .Gallery__caption {
  display: none;
}
.Home.Home__galleryView .Gallery__imgSelected img {
  width: calc(100% - 2px);
  margin-left: auto;
  margin-right: auto;
}
.Home.Home__galleryView .Gallery__imgSelected .Gallery__caption {
  display: block;
}

.Home.Home__imgView img {
  width: 100%;
}

.ImageGallery {
  position: relative;
}

.ImageGallery__tiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-top: 150px;
}

.ImageGallery__img {
  width: 200px;
  height: auto;
  cursor: pointer;
}

.ImageGallery__img {
  margin: 1px;
}

.ImageGallery__imgLarge {
  position: absolute;
  max-width: 800px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  top: -150px;
  left: 0;
  cursor: pointer;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animated {
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  transition-timing-function: linear;
}

.spin {
  animation-duration: 90s;
  -webkit-animation-name: spin;
  animation-name: spin;
  transition-timing-function: linear;
}

@-webkit-keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}

.bounceInDown {
  animation-iteration-count: 1;
  animation-duration: 1s;
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown;
}

* {
  cursor: default;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Akrobat", sans-serif;
  background: #000;
  color: #fff;
  fill: #fff;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body.showOverFlow {
  overflow: visible;
}

a {
  text-decoration: none;
  color: white;
  color: rgba(255, 255, 255, 0.9);
  fill: white;
  fill: rgba(255, 255, 255, 0.9);
  cursor: pointer;
}

ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.hide {
  display: none !important;
}

.display {
  display: block !important;
}

.show {
  display: block;
  opacity: 1;
  -webkit-transition: opacity 0.5s ease-out;
  -moz-transition: opacity 0.5s ease-out;
  -o-transition: opacity 0.5s ease-out;
  transition: opacity 0.5s ease-out;
}

.disappear {
  opacity: 0;
  -webkit-transition: opacity 0.5s ease-out;
  -moz-transition: opacity 0.5s ease-out;
  -o-transition: opacity 0.5s ease-out;
  transition: opacity 0.5s ease-out;
}

/*Split this into individual files and variables*/
.Logo:hover,
.Nav li:hover,
.Nav a:hover,
.Contact__email:hover,
.Contact__socialIcon:hover {
  transition: all 0.5s ease-out;
  color: rgba(255, 129, 156, 0.9);
  fill: rgba(255, 129, 156, 0.9);
}

.App {
  content: "mobile";
  /* display: none; */
}

.HamburgerMenuWrap {
  position: fixed;
  display: none;
}

.HamburgerMenuWrap.hamburger {
  display: block;
}

.PageRoutes.hamburger {
  display: none;
}

.Mandala {
  display: none;
}

@media (min-width: 900px) {
  .App {
    content: "desktop";
  }
  .Mandala {
    display: block;
    width: 350px;
    position: fixed;
    bottom: -100px;
    right: -100px;
    z-index: 5;
  }
  .Mandala__hide {
    display: none;
  }
}

.Contact {
  position: fixed;
  bottom: 10px;
  left: 25px;
  z-index: 5;
}

.Contact__email {
  font-size: 36px;
  cursor: text;
}

.Contact__socialIcon {
  display: inline-block;
  font-size: 48px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin-top: 10px;
}

.Contact__socialWrap a:first-of-type {
  margin-right: 190px;
}

.Contact__socialIcon svg {
  cursor: pointer;
}

@media (min-width: 900px) {
  .Contact {
    bottom: 10px;
  }
  .Contact__email {
    font-size: 24px;
  }
  .Contact__socialIcon {
    font-size: 30px;
    width: 25px;
    height: 25px;
  }
  .Contact__socialWrap a:first-of-type {
    margin-right: 126px;
  }
}

.GitHub {
  width: 100%;
  font-size: 18px;
  margin-top: 150px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.GitHub__panel {
  display: block;
  width: calc(100% - 80px);
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  border: 1px solid #fff;
  transition: all ease 250ms;
  cursor: pointer;
}

.GitHub__panel:hover {
  background: #fff;
  color: #000;
}

.GitHub__title {
  font-style: italic;
  font-size: 40px;
}

.GitHub__description {
  font-size: 30px;
}

@media (min-width: 900px) {
  .GitHub__panel {
    display: inline-block;
    max-width: 200px;
    margin: 5px;
  }
  .GitHub__title {
    font-size: 24px;
  }
  .GitHub__description {
    font-size: 18px;
  }
}

.Hamburger {
  position: fixed;
  right: 5px;
  top: 20px;
  padding: 15px 15px;
  height: 71px;
  display: inline-block;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  outline: none;
  border: none;
  margin: 0;
  overflow: visible;
  z-index: 5;
  cursor: pointer;
}

.Hamburger__mask {
  position: absolute;
  top: -10px;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  cursor: pointer;
  z-index: 11;
}

.Hamburger__box {
  width: 60px;
  height: 60px;
  display: inline-block;
  position: relative;
  outline: none;
}

.Hamburger__inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.Hamburger__inner,
.Hamburger__inner::before,
.Hamburger__inner::after {
  width: 60px;
  height: 6px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  position: absolute;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.Hamburger__inner::before,
.Hamburger__inner::after {
  content: "";
  display: block;
}

.Hamburger__inner::before {
  top: -10px;
}

.Hamburger__inner::after {
  bottom: -10px;
}

.Hamburger__elastic .Hamburger__inner {
  top: 2px;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.Hamburger__elastic .Hamburger__inner::before {
  top: 15px;
  transition: opacity 0.15s 0.4s ease;
}

.Hamburger__elastic .Hamburger__inner::after {
  top: 30px;
  transition: -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55),
    -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.Hamburger__elastic.isActive .Hamburger__inner {
  -webkit-transform: translate3d(0, 10px, 0) rotate(-135deg);
  transform: translate3d(0, 10px, 0) rotate(-135deg);
  transition-delay: 0.1s;
}

.Hamburger__elastic.isActive .Hamburger__inner::before {
  transition-delay: 0s;
  opacity: 0;
}

.Hamburger__elastic.isActive .Hamburger__inner::after {
  top: 20px;
  -webkit-transform: translate3d(0, -20px, 0) rotate(270deg);
  transform: translate3d(0, -20px, 0) rotate(270deg);
  transition-delay: 0.1s;
}

.Hamburger:hover .Hamburger__inner,
.Hamburger:hover .Hamburger__inner::before,
.Hamburger:hover .Hamburger__inner::after {
  background-color: rgba(255, 129, 156, 0.9);
  transition: all 0.5s ease-out;
}

@media (min-width: 1100px) {
  .Hamburger {
    height: 60px;
  }
  .Hamburger__box {
    width: 40px;
    height: 18px;
  }
  .Hamburger__mask {
    top: 0;
    height: 25px;
  }
  .Hamburger__inner,
  .Hamburger__inner::before,
  .Hamburger__inner::after {
    width: 40px;
    height: 2px;
  }
  .Hamburger__elastic .Hamburger__inner::before {
    top: 10px;
  }
  .Hamburger__elastic .Hamburger__inner::after {
    top: 20px;
  }
}

.Logo {
  font-family: Buffalo;
  position: fixed;
  top: 10px;
  left: 25px;
  z-index: 5;
}

.Logo__name {
  font-size: 48px;
  cursor: pointer;
}

.Logo__subtitle {
  font-size: 17px;
  cursor: pointer;
  font-family: Akrobat;
  margin-left: 22px;
  margin-left: 28px;
  margin-top: 3px;
}

@media (min-width: 1100px) {
  .Logo__name {
    font-size: 50px;
  }
}

.Nav {
  font-size: 36px;
  margin-left: 25px;
  margin-top: 150px;
  display: block;
}

.Nav ul {
  overflow-y: hidden;
  height: 42px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
}

.Nav.main ul:first-of-type {
  display: none;
}

.Nav li {
  cursor: pointer;
  margin: 25px;
}

.Nav.hamburger li {
  margin: 0;
}

.Nav ul > li:first-child {
  font-style: italic;
  margin: 0;
}

.Nav.hamburger {
  width: 360px;
}

.Nav.hamburger ul {
  border-bottom: 1px solid #fff;
}

.Nav ul.open {
  height: auto;
}

.Nav.hamburger .Nav__colorBar,
.Nav__colorBar {
  display: none;
}

@media (min-width: 900px) {
  .Nav.main {
    width: 100%;
    font-size: 18px;
    margin-top: 150px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .Nav.main ul {
    overflow: visible;
    height: auto;
  }
  .Nav li {
    margin: 0;
  }
  .Nav.main ul > li:first-child {
    cursor: default;
    color: #fff;
    margin-bottom: 10px;
  }
  .Nav__colorBar {
    display: inline-block;
    width: 1px;
    height: 95px;
    background: #fff;
    margin: 16px;
    margin-top: 47px;
  }
}

.SplashPage {
  width: 100%;
  text-align: center;
  font-family: Buffalo;
  position: fixed;
  top: 200px;
  /* left: 25px; */
  /* z-index: 5; */
}

.SplashPage__name {
  font-size: 100px;
  cursor: pointer;
}

.SplashPage__subtitle {
  font-size: 17px;
  cursor: pointer;
  font-family: Akrobat;
  margin-left: 22px;
  margin-top: 3px;
}

@media (min-width: 1100px) {
  .SplashPage__name {
    font-size: 50px;
  }
}

.Unity .Unity__title {
  margin-top: 125px;
  text-align: center;
}

.Unity .Unity__text {
  text-align: center;
}

.Unity .Unity__tileContianer {
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.Unity .Unity__tileContianer .Unity__tile {
  padding: 20px;
  border: 1px solid #fff;
  transition: all ease 250ms;
  width: 250px;
  cursor: pointer;
  margin: 5px;
}
.Unity .Unity__tileContianer .Unity__tileImg {
  width: 100%;
}

iframe {
  display: block;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.WelcomeAnimation__text {
  font-family: Buffalo;
  font-size: 100px;
  opacity: 1;
  position: absolute;
  color: #000;
  text-align: center;
  top: calc(50% - 150px);
  left: calc(50% - 60px);
  z-index: 11;
}

.WelcomeAnimation__text.returning {
  width: 300px;
  top: calc(50% - 200px);
  left: calc(50% - 150px);
}

.WelcomeAnimation__bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: block;
  width: 100%;
  height: 100%;
  background: #fff;
}

.disappear.WelcomeAnimation__bg {
  z-index: -1;
}
