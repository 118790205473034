.Tokyo__text{
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -300px;
  margin-top: -160px;
}

.Tokyo__letter, .Tokyo__letterOff{
  display: inline-block;
  font: normal 200px 'Nixie One', Helvetica, Arial;
	color: #EEDDE0;
	text-shadow: 0 0 30px #EC637B,
		0 0 10px rgba(230, 161, 173, 0.41),
		0 0 100px #E6A1AD,
		0 0 500px #F36780,
		5px 9px 5px rgba(0, 0, 0, 0.5);
}


.Tokyo__letterOff {
	color: rgba(46, 46, 46, 0.61);
	text-shadow: 7px 7px 5px rgba(0, 0, 0, 0.5);
}


.Tokyo_bg{
  filter: grayscale(100%);
  opacity:0.2;
}
