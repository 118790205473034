.SpecialRequest__bg {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  background: url(https://static1.squarespace.com/static/53585febe4b02ff06cdbbba3/t/5537a674e4b08d87cbebaf0d/1429710458367/Music-Studio-Monochrome-Greyscale.jpg?format=1500w);
  background-size: cover;
  z-index: 1;
  opacity: 0.2;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin-reverse {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.SpecialRequest__spinner {
  animation-duration: 1200ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: spin;
  animation-name: spin;
  display: block;
  width: auto;
  position: absolute;
  height: calc(100% - 100px);
  left: 50px;
  top: 100px;
  z-index: 1;
}

.SpecialRequest__info {
  font-family: arial, sans-serif;
  font-style: italic;
  position: absolute;
  bottom: 25px;
  right: 25px;
  background: #000;
  font-size: 16px;
  color: #fff;
  z-index: 2;
  padding: 3px;
  border-radius: 5px;
}

.SpecialRequest__loadScreen {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background: #000;
  display: block;
  width: 100%;
  height: 100%;
}

.SpecialRequest__loadText {
  color: #fff;
  font-size: 34px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
}

.SpecialRequest__thumbnailContainer {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.SpecialRequest__thumbnail,
.SpecialRequest__thumbnailActive {
  width: 100px;
  height: 100px;
  cursor: pointer;
  border-radius: 50px;
  height: auto;
  display: inline-block;
  opacity: 0.3;
}

.SpecialRequest__thumbnailActive {
  opacity: 1;
}
